import {FunctionComponent, useState} from 'react';

import {FormOutlined, RightOutlined, BarsOutlined} from '@ant-design/icons';
import {Button, Divider, Flex, Typography, Popover} from 'antd';
import classNames from 'classnames';
import {EProfileTabs} from 'Core/Enums';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import useStores from 'Stores';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';

const {Title} = Typography;

interface HeaderProfileProps {
    activeTab: EProfileTabs;
    setActiveTab: (tab: EProfileTabs) => void;
}

const HeaderProfile: FunctionComponent<HeaderProfileProps> = ({activeTab, setActiveTab}) => {
    const navigate = useNavigate();
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle} = useStores().historyStore;
    const {resetMessages, setIsLoading, setIsSidebarOpen, modifier} = useStores().searchStore;
    const {userInfo, isUserInfoLoading} = useStores().profileStore;
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const {logout} = useAuth();

    const handleLogout = () => {
        void logout();
        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
    };

    const handleNewChat = () => {
        setIsLoading(false);
        resetMessages();
        setPrompt(null);
        setCurrentHistoryChatId(null);
        setCurrentHistoryChatTitle(null);
        navigate(ROUTES.APP.PATH);
    };

    const navigationButtons = [
        {
            label: 'Настройки',
            onClick: () => {
                setActiveTab(EProfileTabs.settings);
                setIsPopoverOpen(false);
            },
            isActive: activeTab === EProfileTabs.settings,
            show: true,
        },
        {
            label: 'Пополнение',
            onClick: () => {
                setActiveTab(EProfileTabs.tariffs);
                setIsPopoverOpen(false);
            },
            isActive: activeTab === EProfileTabs.tariffs,
            show: true,
        },
        {
            label: 'Транзакции',
            onClick: () => {
                setActiveTab(EProfileTabs.transactions);
                setIsPopoverOpen(false);
            },
            isActive: activeTab === EProfileTabs.transactions,
            show: !userInfo?.is_business,
        },
        {
            label: 'Выйти',
            onClick: handleLogout,
            isActive: false,
            show: true,
        },
    ];

    const renderNavigationButton = ({label, onClick, isActive, show}: (typeof navigationButtons)[0]) => {
        if (!show) return null;

        return (
            <Button
                key={label}
                type="default"
                shape="round"
                onClick={onClick}
                className={isActive ? s.buttonActive : s.button}
            >
                {label}
            </Button>
        );
    };

    const PopoverContent = (
        <Flex vertical gap="small">
            {navigationButtons.map(renderNavigationButton)}
        </Flex>
    );

    const renderMobileHeader = () => (
        <Flex align="center" gap="large" justify="space-between" className={s.mobileFlex}>
            <Flex className={s.siderContainer} gap="middle">
                <Button
                    size="large"
                    type="default"
                    icon={<RightOutlined />}
                    shape="circle"
                    onClick={() => setIsSidebarOpen(true)}
                />
                <Button
                    size="large"
                    type="default"
                    icon={<FormOutlined />}
                    shape="circle"
                    className={s.newChatBtn}
                    onClick={handleNewChat}
                />
            </Flex>
            <Popover
                content={PopoverContent}
                placement="bottomRight"
                trigger="click"
                arrow={false}
                className={s.popover}
                open={isPopoverOpen}
            >
                <Button
                    size="large"
                    type="default"
                    icon={<BarsOutlined />}
                    shape="circle"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                />
            </Popover>
        </Flex>
    );

    const renderDesktopHeader = () => (
        <Flex align="center" gap="large" className={classNames(s.flex, modifier && s[`flex--${modifier}`])}>
            <Title className={s.title} level={1}>
                Профиль
            </Title>
            {modifier !== 'LargeMobile' ? (
                <Flex className={s.tabs} align="center" gap="large">
                    {navigationButtons.map(renderNavigationButton)}
                </Flex>
            ) : (
                <Popover
                    content={PopoverContent}
                    placement="bottomRight"
                    trigger="click"
                    arrow={false}
                    className={s.popover}
                    open={isPopoverOpen}
                >
                    <Button
                        size="large"
                        type="default"
                        icon={<BarsOutlined />}
                        shape="circle"
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    />
                </Popover>
            )}
        </Flex>
    );

    return (
        <>
            {isUserInfoLoading ? null : modifier === 'Mobile' ? renderMobileHeader() : renderDesktopHeader()}
            {!isUserInfoLoading && <Divider className={s.divider} />}
        </>
    );
};

export default observer(HeaderProfile);
