import {FunctionComponent, ReactElement, RefObject, useEffect, useState} from 'react';

import {CheckOutlined, CopyOutlined, EditOutlined, CloseOutlined} from '@ant-design/icons';
import {Button, Col, Row, Image as AntdImage, Typography, Flex} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import MessageContent from './MessageContent';
import s from './styles.module.scss';
import {AI_MODELS} from '../../../Core/Const/AiModels';
import {ERole, AnswerMode, EResponseError} from '../../../Core/Enums';
import {useDeepseekChat} from '../../../Services/Deepseek';
import {useStableDiffusion} from '../../../Services/Image';
import {useOpenAIChat} from '../../../Services/Openai/chat';
import {useOpenAIImage} from '../../../Services/Openai/image';
import {usePrompts} from '../../../Services/Prompts';
import {textPriceParser} from '../../../Services/utils';
import {useYandexChat} from '../../../Services/Yandex';
import {delay} from '../../Utils';

interface IProps {
    message: any;
    bottomRef?: RefObject<any>;
}

const Message: FunctionComponent<IProps> = ({message, bottomRef}: IProps) => {
    const {userInfo} = useStores().profileStore;
    const containerClass = message.role === ERole.USER ? 'userContainer' : 'notUserContainer';
    const {modelsOptions} = useStores().profileStore;

    const {
        modifier,
        addMessage,
        setCurrentMessage,
        messages,
        aiMode,
        isAiAnswering,
        editingMessageId,
        setEditingMessageId,
    } = useStores().searchStore;
    const {currentHistoryChatId} = useStores().historyStore;
    const {updateHistoryChatWithRank, updateHistoryChat, updateChat} = usePrompts();
    const {sendChatMessageInStreamingMode} = useOpenAIChat();
    const {sendChatMessageInStreamingModeYandex} = useYandexChat();
    const {sendChatMessageInStreamingModeDeepseek} = useDeepseekChat();
    const {convertTextToImage} = useStableDiffusion();
    const {textToImage} = useOpenAIImage();

    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(message.content);

    const [image, setImage] = useState(<></>);

    const handleResponse = async (resp: any, aiMode: string) => {
        if (resp === EResponseError.SERVICE_UNAVAIBLE) {
            setCurrentMessage(ERole.ASSISTANT, resp, '', aiMode);
            addMessage();
        } else {
            const imageUrls = resp.imageUrls || [resp.imgUrl];
            const {text, price} = textPriceParser(imageUrls);

            if (aiMode === AnswerMode.TEXT_TO_IMAGE) {
                JSON.parse(text).forEach((imageUrl: any) => {
                    setCurrentMessage(ERole.ASSISTANT, imageUrl, price, aiMode);
                    addMessage();
                });
            }

            if (aiMode === AnswerMode.DALLE_2 || aiMode === AnswerMode.DALLE_3) {
                const obj = JSON.parse(text);
                setCurrentMessage(ERole.ASSISTANT, obj.img_url, price, aiMode);
                addMessage();
            }
        }

        if (messages.length > 1 && !isAiAnswering) {
            void updateChat();
            if (currentHistoryChatId) void updateHistoryChat(currentHistoryChatId);
        }
    };

    useEffect(() => {
        const messageContent = async (): Promise<void> => {
            const delayMs = 1000;

            if (
                (message.mode === AnswerMode.DALLE_2 ||
                    message.mode === AnswerMode.DALLE_3 ||
                    message.mode === AnswerMode.TEXT_TO_IMAGE) &&
                message.role === ERole.ASSISTANT
            ) {
                await delay(delayMs);
                const img = new Image();
                img.src = message.content;
                img.onload = async () => {
                    setImage(<AntdImage src={message.content} />);
                    await delay(delayMs);
                    bottomRef?.current.scrollIntoView({behavior: 'smooth'});
                };
            }
        };

        void messageContent();
    }, [message]);

    const modelTitle =
        message.role === ERole.ASSISTANT
            ? modelsOptions.find((item) => {
                  return item.name === message.mode;
              })?.title
            : 'Вы';

    const handleOnClickCopy = async (): Promise<void> => {
        await navigator.clipboard.writeText(message.content);
    };

    const renderPrice = (): ReactElement =>
        message.price && !userInfo?.is_business ? <Typography.Text>{`-${message.price}`}</Typography.Text> : <></>;

    const handleEditClick = (): void => {
        setEditingMessageId(message.id);
        setIsEditing(true);
    };

    const handleChangeClick = async (): Promise<void> => {
        await updateHistoryChatWithRank(message, currentHistoryChatId);

        setCurrentMessage(ERole.USER, editedContent, aiMode);
        addMessage();
        setEditingMessageId(null);
        setIsEditing(false);

        if (currentHistoryChatId) await updateHistoryChat(currentHistoryChatId);

        if (AI_MODELS.openai_text.includes(aiMode)) {
            await sendChatMessageInStreamingMode();
        } else if (AI_MODELS.yandex.includes(aiMode)) {
            await sendChatMessageInStreamingModeYandex();
        } else if (AI_MODELS.deepseek.includes(aiMode)) {
            await sendChatMessageInStreamingModeDeepseek();
        } else if (AI_MODELS.stable_diffusion.includes(aiMode)) {
            await handleResponse(await convertTextToImage(), aiMode);
        } else if (AI_MODELS.openai_image.includes(aiMode)) {
            await handleResponse(await textToImage(), aiMode);
        }
    };

    const handleCancelEdit = (): void => {
        setEditingMessageId(null);
        setIsEditing(false);
        setEditedContent(message.content);
    };

    return (
        <Row className={classNames(s[containerClass], modifier && s[`${containerClass}--${modifier}`])}>
            <Row justify="space-between" wrap>
                <Row align="top" style={{width: '100%'}}>
                    <Col className={s.messageCol}>
                        {(message.mode === AnswerMode.DALLE_2 ||
                            message.mode === AnswerMode.DALLE_3 ||
                            message.mode === AnswerMode.TEXT_TO_IMAGE) &&
                        message.role === ERole.ASSISTANT &&
                        !Object.values(EResponseError).includes(message.content) ? (
                            image
                        ) : isEditing ? (
                            <TextArea
                                className={s.messageInput}
                                value={editedContent}
                                onChange={(e) => setEditedContent(e.target.value)}
                                autoSize
                            />
                        ) : (
                            <MessageContent content={message.content} />
                        )}
                    </Col>
                </Row>
                {message.role === ERole.ASSISTANT ? (
                    <Row className={s.controlsRow} align="middle">
                        <Button
                            type="link"
                            size="large"
                            icon={<CopyOutlined className={s.copyIcon} />}
                            className={s.messageActBtn}
                            onClick={handleOnClickCopy}
                        />
                        <Typography.Text className={s.modelTitle}>{modelTitle}</Typography.Text>
                        {renderPrice()}
                    </Row>
                ) : (
                    (editingMessageId === null || editingMessageId === message.id) && (
                        <Flex vertical align="center" className={s.editButtonContainer}>
                            {isEditing ? (
                                <Flex gap="small">
                                    <Button
                                        type="link"
                                        size="large"
                                        icon={<CheckOutlined className={s.copyIcon} />}
                                        className={s.messageEditBtn}
                                        onClick={handleChangeClick}
                                    />
                                    <Button
                                        type="link"
                                        size="large"
                                        icon={<CloseOutlined className={s.copyIcon} />}
                                        className={s.messageEditBtn}
                                        onClick={handleCancelEdit}
                                    />
                                </Flex>
                            ) : (
                                editingMessageId !== message.id && (
                                    <Button
                                        type="link"
                                        size="large"
                                        icon={<EditOutlined className={s.copyIcon} />}
                                        className={s.messageEditBtn}
                                        onClick={handleEditClick}
                                    />
                                )
                            )}
                        </Flex>
                    )
                )}
            </Row>
        </Row>
    );
};

export default observer(Message);
